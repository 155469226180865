@import "variables";

html {
	font-size: 28px;
	line-height: 1.1;
	font-family: "Share Tech", "Tahoma";
	visibility: visible;

	background: $background;
	color: #e6e6e6;
}

body {
	display: flex;
	align-items: center;
	justify-content: center;
}

ul {
	padding: 0;
    list-style-type: none;
}

li {
	padding: 0 0 20px 0;
}

a {
	color: #e6e6e6;
}

a:visited {
	color: #e6e6e6;
}

p {
	margin: 0;
}

#content {
	width: 80%;
}

.no_underline {
	text-decoration: none;
}
