
//
// VARIABLES
//

// Colors
$link: #58A6FF;

// Grays
$black: #000;
$text: #fff;
$subtitle: #8b949e;
$lightGray: #161b22;
$background: #252A34;
$title: #fff;
$highlight_background: #161b22;
$highlight_border: #30363d;
$item_background: #fff;

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}
